@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  color: #4a4a4a;
  background: #ffffff;
  margin: 0;
  // font-family: "Helvetica", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  //   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  // font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Helvetica", "Roboto", sans-serif;
  // font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
