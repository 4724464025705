@import "../../assets/styles/main.module";

.intro {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 100;
  background: #ffffff;
  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      position: relative;
      z-index: 1;
      margin: auto;
      display: flex;
      flex-flow: column wrap;
      text-align: center;
      opacity: 0;
      h1 {
        font-size: 44px;
        line-height: 46px;
        font-weight: 500;
        letter-spacing: 2px;
        margin: 0px;
        @include media(mobile) {
          font-size: 38px;
          line-height: 40px;
        }
      }
      h4 {
        font-size: 36px;
        line-height: 38px;
        font-weight: 300;
        margin: 3% 0px 4%;
        letter-spacing: 1px;
        @include media(mobile) {
          font-size: 30px;
          line-height: 32px;
        }
      }
      .more {
        cursor: pointer;
        opacity: 0;
        display: block;
        margin-top: 10px;
        font-size: 14px;
        text-decoration: none;
        align-self: center;
        padding: 10px 15px;
        border: none;
        border-bottom: 1px solid color(typo);
        transition: 0.3s;
        @media screen and (max-width: 991px) {
          margin-top: 5px;
        }
        &:hover {
          transition: 0.8s;
          box-shadow: 0px 5px 10px 1px rgba(51, 51, 51, 0.4);
          border-radius: 5px;
          transform: translateY(-2px);
        }
      }
    }
  }
  .splash {
    position: fixed;
    width: 100%;
    height: 0%;
    bottom: 0;
    left: 0;
    background: color(accentSecond);
    z-index: 2;
  }
}
