@import "../../../assets/styles/main.module";

.myInfo {
  position: relative;
  width: 85%;
  margin: 20% auto 0px;
  z-index: 1;
  @include media(tablet) {
    width: 49%;
    margin: 0;
  }
  h1,
  p {
    color: color(typo);
  }
  h1 {
    font-size: 60px;
    font-weight: 500;
    line-height: 62px;
    letter-spacing: 2px;
    text-align: center;
    margin: 0px;
    @include media(tablet) {
      font-size: 65px;
      line-height: 67px;
      letter-spacing: 2px;
      text-align: left;
    }
  }
  p {
    font-size: 28px;
    line-height: 30px;
    margin: 15px 0px 0px;
    letter-spacing: 1px;
    text-align: center;
    @include media(tablet) {
      text-align: left;
    }
  }
}
