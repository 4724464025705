@import "../../assets/styles/main.module";

.artwork {
  .backicon {
    cursor: pointer;
    width: 20px;
    position: absolute;
    top: -10px;
    left: 0;
    transform: translateY(-100%);
    &:hover {
      opacity: 0.6;
    }
  }
  .splash {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 0%;
    background: color(accentSecond);
    z-index: 99;
    &.splashVertical {
      width: 0%;
      height: 100%;
    }
  }
  .bigWord {
    cursor: default;
    user-select: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 9em;
      // font-weight: 500;
      position: relative;
      white-space: nowrap;
      word-break: keep-all;
    }
  }
  .artworkContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    padding-bottom: 40px;
    width: 100%;
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    align-items: center;
    @include media(miniDesktop) {
      max-width: 1200px;
      left: 0;
      right: 0;
      margin: auto;
    }
    .artworkContent {
      position: relative;
      width: 90%;
      margin: 0px auto;
      padding: 20px 0px 50px;
      border: 1px solid #4a4a4a;
      @include media(miniDesktop) {
        display: flex;
        align-items: flex-start;
        margin: 70px auto 70px 4%;
        width: 97%;
      }
      .logo {
        display: none;
        position: absolute;
        top: 0;
      }
      .canva {
        width: 100%;
        padding-bottom: 60%;
        background-position: 50% 0%;
        // box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);
        box-shadow: 0 30px 60px 0 rgba(92, 99, 105, 0.15);
        background-size: cover;
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
        }
        @include media(miniDesktop) {
          width: 50%;
          padding-bottom: 30%;
          overflow: hidden;
        }
        &.canva1 {
          margin-left: -5%;
          @include media(miniDesktop) {
            margin-left: -3%;
          }
        }
        &.canva2 {
          margin-left: 5%;
          margin-top: -10%;
          @include media(miniDesktop) {
            margin-left: -3%;
          }
        }
        &.canvaBot {
          background-position: 50% 100%;
          img {
            top: unset;
            bottom: 0;
          }
        }
      }
    }
    .btnView {
      position: absolute;
      z-index: 3;
      right: 20px;
      bottom: 10px;
      @include media(miniDesktop) {
        right: 50px;
        bottom: 10px;
      }
      a {
        font-size: 14px;
        line-height: 16px;
        color: color(typo);
        text-decoration: none;
        border-bottom: 1px;
        padding-bottom: 5px;
      }
    }
    .title {
      position: absolute;
      right: 10px;
      bottom: -30px;
      border-right: 1px solid #4a4a4a;
      padding-top: 50px;
      padding-right: 5px;
      @include media(miniDesktop) {
        right: 3%;
        bottom: -5px;
        border: none;
      }
      p {
        margin: 0px;
        font-size: 14px;
        line-height: 16px;
        @include media(miniDesktop) {
          border-top: 1px solid #4a4a4a;
          padding-top: 5px;
          padding-left: 30px;
          transform: rotate(-90deg) translateX(calc(100% - 35px));
          transform-origin: top right;
        }
      }
    }
  }
}
