$colors: (
  primary: #00aeef,
  typo: #4a4a4a,
  accent: #15283f,
  accentSecond: #b3c4d3,
  accentLight: lighten(#15283f, 40%)
);

$boxShadow: 0 2px 6px 0 rgba(49, 53, 59, 0.12);

$mediaQueries: (
  desktop: 1200px,
  miniDesktop: 992px,
  tablet: 768px,
  mobileLarge: 480px
);

@function color($colorName) {
  @return map-get($colors, $colorName);
}

@function mediaFunction($pixel) {
  @return map-get($mediaQueries, $pixel);
}
