@import "../../assets/styles/main.module";

.home {
  opacity: 0;
  @include media(tablet) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 80%;
    height: 100vh;
    margin: auto;
  }
  .bigWord {
    cursor: default;
    user-select: none;
    position: fixed;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      color: #f7f7f7;
      text-align: center;
      position: relative;
      white-space: nowrap;
      margin: auto;
      font-size: 25em;
      font-weight: 400;
      word-break: keep-all;
    }
  }
}
