@import "../../../assets/styles/main.module";

.latestWork {
  position: relative;
  width: 85%;
  margin: 100px auto 20%;
  z-index: 1;
  @include media(tablet) {
    width: 37%;
    margin: 0 3% 0 0;
    display: flex;
    flex-flow: column;
    transform: skew(10deg);
  }
  h5 {
    text-align: center;
    margin: 0px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    @include media(tablet) {
      text-align: left;
      margin: 0px;
      font-size: 20px;
      line-height: 22px;
      transform: skew(-10deg);
    }
  }
  .viewMorePlc {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    @include media(tablet) {
      transform: skew(-10deg);
      margin-top: 15px;
    }
    .viewMore {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      @include media(miniDesktop) {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .arrow {
      @include imgCont;
      width: 20px;
      height: 20px;
      margin: 0 0 0 10px;
    }
  }
  .latestContent {
    margin-top: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .latestItem {
      width: 100%;
      @include media(tablet) {
        width: calc(50% - 5px);
      }
      &.bigLatest {
        position: relative;
        overflow: hidden;
        background: #eaeaea;
        padding: 50%;
        margin: 50px 0px 0px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
        @include media(tablet) {
          padding: 0;
          margin: 0;
        }
      }
      &.smallLatest {
        display: flex;
        flex-flow: column;
        .smallItem {
          padding: 50%;
          margin: 50px 0px 0px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
          position: relative;
          overflow: hidden;
          background: #eaeaea;
          @include media(tablet) {
            padding: 60% 50%;
            margin: 0;
          }
          &:nth-of-type(2) {
            background: #eaeaea;
            @include media(tablet) {
              margin-top: 10px;
            }
          }
        }
      }
      .artwork {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: 50% 0%;
        background-size: cover;
        overflow: hidden;
        @include media(tablet) {
          transform: scale(1.5) skew(-10deg);
          &:hover {
            transform: scale(1.6) skew(-10deg);
            transition: 0.5s;
          }
        }
        transform-origin: 10px 0;
        transition: 0.5s;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
      }
      // .smallItem{
      //   &:nth-of-type(1){
      //     .imgCont{
      //       margin-top: 0;
      //     }
      //   }
      //   .imgCont{
      //     margin-top: 4%;
      //   }
      // }
    }
  }
}
