@import "../../assets/styles/main.module";

.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    li {
      list-style: none;
      margin: 0px 5px;
      &.active,
      &:hover {
        a {
          height: 60px;
        }
      }
      a {
        cursor: pointer;
        padding: 10px;
        text-decoration: none;
        width: auto;
        display: block;
        transition: 0.2s;
        height: 40px;
        span {
          width: 2px;
          height: 100%;
          content: "";
          display: block;
          background: #b0b0b0;
        }
      }
    }
  }
}
